import { lighten } from 'polished';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { colors } from '../../styles/colors';
import SubscribeForm from './SubscribeForm';

const SubscribeFormSection = styled.section`
  margin: 1.5em 0 60px 0;
  padding: 6.5vw 7vw 7vw;
  border: ${lighten('0.02', colors.whitegrey)} 1px solid;
  text-align: center;
  border-radius: 4px;
  background-image: radial-gradient(circle, #f1cbb4, #f3c4a8, #f5bd9c, #f7b591, #f8ae86);

  p {
    margin-bottom: 1em;
    color: #272629;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: 300;
    text-align: center;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 420px;
  }

  .form-group {
    flex-grow: 1;
  }
  @media (max-width: 650px) {
    p {
      font-size: 1.6rem;
    }
  }
`;

const SubscribeFormTitle = css`
  margin: 0 0 3px 0;
  padding: 0;
  color: ${colors.darkgrey};
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 800;
  @media (max-width: 650px) {
    font-size: 2.4rem;
  }
`;

export interface SubscribeProps {
  title: string;
}

const Subscribe: React.FC<SubscribeProps> = () => {
  return (
    <SubscribeFormSection>
      <h3 css={SubscribeFormTitle}>Subscribe to the blog</h3>
      <p>Receive all the latest posts right into your inbox</p>
      <SubscribeForm />
    </SubscribeFormSection>
  );
};

export default Subscribe;
